import React from 'react'
import Alert from 'facit/components/bootstrap/Alert';
import Button from 'facit/components/bootstrap/Button';
import "../styles/notifications/alert.scss"

const AlertComponent = (props: { title: String, message: String, id: Number, readAt: Date, readNotification: Function, createdAt: string }): React.ReactElement => {
  const { title, message, id, readAt, readNotification, createdAt } = props

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
    
    return date.toLocaleDateString('pt-BR', options);
  }

  return (
    <Alert tag="h2" isLight={true} color={!!readAt ? "dark" : "info"}>
      <>
        <h5>{title}</h5>
        <span>{message}</span>

        <sub className="alert-date">{formatDate(createdAt)}</sub>

        {
          !readAt &&
          <Button
            className="alert-button"  
            color="link"
            onClick={() => { readNotification(id) }}
          > 
            Marcar como lido
          </Button>
        }
      </>
    </Alert>
  );
}

export default AlertComponent
