import { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import classNames from "classnames";
import { motion } from "framer-motion";
import Brand from "../Brand/Brand";
import Navigation, { NavigationLine } from "../Navigation/Navigation";
import ThemeContext from "../../contexts/themeContext";
import useAsideTouch from "../../../facit/hooks/useAsideTouch";
import Icon from '../../components/icon/Icon'
import { hasPermission } from "utils/permissions";
import { useTranslation } from "react-i18next";

const Aside = () => {
  const { t } = useTranslation('asides', { keyPrefix: 'course' })
  const navigate = useNavigate()

  const menu = {
    index: {
      id: "Courses",
      text: t('courses'),
      icon: "Home",
      path: "organizations/:id/courses",
      submenu: null,
    }
  }

  const { asideStatus, setAsideStatus } = useContext(ThemeContext);
  const { asideStyle, touchStatus, hasTouchButton, asideWidthWithSpace, x } =
    useAsideTouch();

  const location = useLocation()

  const feedbackClass = () => {
    let classNames = 'navigation-link navigation-link-pill'

    location.pathname == '/feedback' && (classNames += ' active')

    return classNames
  }

  const logOut = () => {
    localStorage.removeItem('organization_token')
    localStorage.removeItem('me')

    navigate('/login')

    window.location.reload()
  }

  const switchProfile = () => {
    navigate('/admin/organizations')
  }

  return (
    <>
      <motion.aside
        style={asideStyle}
        className={classNames(
          "aside",
          { open: asideStatus },
          {
            "aside-touch-bar": hasTouchButton,
            "aside-touch-bar-close": !touchStatus && hasTouchButton,
            "aside-touch-bar-open": touchStatus && hasTouchButton,
          }
        )}
      >
        <div className='aside-head'>
          <Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
        </div>

        <div className='aside-body'>
          <Navigation menu={menu} id='aside-dashboard' />
          <NavigationLine />
        </div>

        <div className='aside-foot'>
          { hasPermission('show_admin_screen') && (
            <nav aria-label='aside-bottom-user-menu-2'>
              <div className='navigation'>
                <div
                  role='presentation'
                  className='navigation-item cursor-pointer'
                  onClick={switchProfile}
                >
                  <span className='navigation-link navigation-link-pill'>
                    <span className='navigation-link-info'>
                      <Icon icon='toggleOff' className='navigation-icon' />
                      <span className='navigation-text'>{t('admin')}</span>
                    </span>
                  </span>
                </div>
              </div>
            </nav>
          )}

          <nav aria-label='aside-bottom-user-menu-2'>
            <div className='navigation'>
              <div
                role='presentation'
                className='navigation-item cursor-pointer'
                onClick={() => navigate('/feedback')}
              >
                <span className={feedbackClass()} >
                  <span className='navigation-link-info'>
                    <Icon icon='feedback' className='navigation-icon' />
                    <span className='navigation-text'>Feedback</span>
                  </span>
                </span>
              </div>
            </div>
          </nav>

          <nav aria-label='aside-bottom-user-menu-2'>
            <div className='navigation'>
              <div
                role='presentation'
                className='navigation-item cursor-pointer'
                onClick={() => logOut()}>
                <span className='navigation-link navigation-link-pill'>
                  <span className='navigation-link-info'>
                    <Icon icon='Logout' className='navigation-icon' />
                    <span className='navigation-text'>Logout</span>
                  </span>
                </span>
              </div>
            </div>
          </nav>
				</div>
      </motion.aside>
    </>
  );
};

export default Aside;
