export const role = () => {
  return me().role || {}
}

export const roleName = () => {
  return role().name
}

export const hasPermission = (name: string) => {
  const permissions = role().permissions || {}

  return permissions[name] && permissions[name].enabled
}

const me = () => {
  if (!localStorage.me) return {}

  return JSON.parse(localStorage.me)
}
