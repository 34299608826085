import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { motion } from "framer-motion";
import Brand from "../Brand/Brand";
import Navigation from "../Navigation/Navigation";
import { adminMenu } from "../../../config/adminMenu";
import ThemeContext from "../../contexts/themeContext";
import useAsideTouch from "../../../facit/hooks/useAsideTouch";
import Icon from '../../components/icon/Icon'

const Aside = () => {
  const navigate = useNavigate();

  const { asideStatus, setAsideStatus } = useContext(ThemeContext);
  const { asideStyle, touchStatus, hasTouchButton } =
    useAsideTouch();

  const logOut = () => {
    localStorage.removeItem('organization_token')
    localStorage.removeItem('me')

    navigate('/login')

    window.location.reload()
  }

  const switchProfile = () => {
    navigate('/organizations')
  }

  return (
    <>
      <motion.aside
        style={asideStyle}
        className={classNames(
          "aside",
          { open: asideStatus },
          {
            "aside-touch-bar": hasTouchButton,
            "aside-touch-bar-close": !touchStatus && hasTouchButton,
            "aside-touch-bar-open": touchStatus && hasTouchButton,
          }
        )}
      >
        <div className='aside-head'>
          <Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
        </div>
        <div className='aside-body'>
          <Navigation menu={adminMenu} id='aside-admin' />
        </div>

        <div className='aside-foot'>
          <nav aria-label='aside-bottom-user-menu-2'>
            <div className='navigation'>
              <div
                role='presentation'
                className='navigation-item cursor-pointer'
                onClick={switchProfile}
              >
                <span className='navigation-link navigation-link-pill'>
                  <span className='navigation-link-info'>
                    <Icon color="success" icon='toggleOn' className='navigation-icon' />
                    <span className='navigation-text'>Administração Lanse</span>
                  </span>
                </span>
              </div>
            </div>
          </nav>

          <nav aria-label='aside-bottom-user-menu-2'>
            <div className='navigation'>
              <div
                role='presentation'
                className='navigation-item cursor-pointer'
                onClick={() => navigate('/feedback')}
              >
                <span className='navigation-link navigation-link-pill'>
                  <span className='navigation-link-info'>
                    <Icon icon='feedback' className='navigation-icon' />
                    <span className='navigation-text'>Feedback</span>
                  </span>
                </span>
              </div>
            </div>
          </nav>

          <nav aria-label='aside-bottom-user-menu-2'>
            <div className='navigation'>
              <div
                role='presentation'
                className='navigation-item cursor-pointer'
                onClick={() => logOut()}
              >
                <span className='navigation-link navigation-link-pill'>
                  <span className='navigation-link-info'>
                    <Icon icon='Logout' className='navigation-icon' />
                    <span className='navigation-text'>Logout</span>
                  </span>
                </span>
              </div>
            </div>
          </nav>
				</div>
      </motion.aside>
    </>
  );
};

export default Aside;
