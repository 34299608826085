import App from './App'
import { createRoot } from 'react-dom/client'
import './styles.scss'
import './i18n'
import * as amplitude from '@amplitude/analytics-browser';

const container = document.getElementById('root')
amplitude.init('5426bb71f08e7b48f8801ce184bc144f');

// eslint-disable-next-line
const root = createRoot(container!)

root.render(
  <App />
)
